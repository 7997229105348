<template>
    <div class="max-w-7xl mx-auto py-5 flex flex-wrap gap-6 justify-center md:justify-between">
        <div class="flex items-center gap-1.5">
            Inhaber vom System
            <img src="/img/logo-zuerioberland-small.png" class="h-16 dark:invert" />
        </div>
        <ul class="flex gap-2 items-center">
            <li><NuxtLink to="/privacy">Datenschutz</NuxtLink></li>
            <li><NuxtLink to="/imprint">Impressum</NuxtLink></li>
            <li><NuxtLink to="/terms">AGB</NuxtLink></li>
        </ul>
    </div>
</template>